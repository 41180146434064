@import 'styles/variables';
@import "styles/typography";

#cx-custom-title {
    .input-ticket-title {
        padding-left: 0;
        width: 235px;

        @media (max-width: $tablet) {
            width: 188px;
        }

        & .mdc-text-field__input {
            padding: 0 5px;
            font-size: $largeplus;

            @media (max-width: $phone) {
                font-size: $medium;
            }
        }
    }

    #title {
        max-width: 849px;
        font-size: $largeplus;

        @media (max-width: $tablet) {
            max-width: 660px;
        }

        @media (max-width: $phone) {
            max-width: 275px;
            font-size: $medium;
        }

        & ~ span {
            display: none;

            @media (max-width: $tablet) {
                display: inline-block;
            }
        }
    }

    button {
        width: 24px;
    }

    &:hover {
        #title ~ span {
            display: inline-block;
        }
    }

    .mdc-list-item__primary-text {
        color: $black !important;
        line-height: 14px;
        letter-spacing: 0.114px;
        @extend .font-regular;
        @extend .text-regular;
    }

    .mdc-list-item:hover {
        background-color: $lilac-hover;
        border-radius: 4px;
    }

    .mdc-list-item:not(:last-child) {
        border-bottom: solid 1px $secondaryborder;
    }

    .mdc-menu {
        padding-left: 15px;
        padding-right: 5px;

        & > div > div {
            width: 268px;
        }
    }

    .mdc-menu-surface {
        width: 300px !important;
        height: 331px !important;
        box-shadow: 0 0 10px rgb(0 0 0 / 16%);
        border-radius: 4px;

        &.surface-question {
            height: 280px !important;
        }

        h5 {
            line-height: 12px;
            letter-spacing: 0.095px;
            @extend .text-tiny;
            @extend .font-light;
        }

        &::-webkit-scrollbar {
            width: 18px;
        }

        &::-webkit-scrollbar-thumb {
            background-clip: content-box;
            background-color: $purple-text;
            border: 7px solid transparent;
            border-radius: 20px;

            &:hover {
                background-color: $purple-text;
            }

            &:active {
                background-color: $purple-text;
            }
        }

        & .close-icon {
            margin-top: 10px;
            width: min-content;
        }
    }

    & form {
        width: 235px;

        @media (max-width: $tablet) {
            width: 188px;
        }

        & span {
            display: none;

            @media (max-width: $tablet) {
                display: inline-block;
            }
        }

        &:hover {
            & span {
                display: inline-block;
            }
        }
    }

    .question-container {
        width: 268px;
        height: 232px;
        padding-left: 5px;

        & > div {
            width: 243px;
        }

        & .question-title {
            line-height: 14px;
            letter-spacing: 0.114px;
        }

        & .question-answer {
            line-height: 14px;
            letter-spacing: 0.448px;
        }
        &::-webkit-scrollbar {
            width: 18px;
        }

        &::-webkit-scrollbar-thumb {
            background-clip: content-box;
            background-color: $purple-text;
            border: 7px solid transparent;
            border-radius: 20px;

            &:hover {
                background-color: $purple-text;
            }

            &:active {
                background-color: $purple-text;
            }
        }
    }
}
