@use 'styles/variables' as v;
@use 'styles/animations' as a;
@use 'styles/mixins' as m;

#cx-customer-portal-navigation {
    --active-background: var(--Primary-300) !important;
    --hover-background: var(--Primary-200);
    --font-color: var(--Color-Grey-900);
    --active-color: var(--font-color);
    --sticky-color: var(--Color-Grey-600);

    display: inline-block;
    @include m.full-size();
    min-height: 400px;
    position: relative;

    &.is-dropdown {
        @include m.container-fluid(294px);
    }

    .option-title {
        white-space: nowrap;
        display: inline !important;
        @include m.truncate-text(1);
        @include m.font(v.$medium, 400, 20px, 0.04px, var(--font-color));
    }

    .style-orders {
        padding-inline: 0;
    }

    .navigation-pages {
        background: var(--Color-Grey-100);
        border-radius: 4px;
        z-index: 50;
        outline-color: transparent;

        &:has(.skeleton-loading) {
            @include m.skeleton-background();
        }

        &.is-dropdown {
            @include m.regular-border(false);

            .link {
                &:hover {
                    background-color: var(--hover-background);
                }
                &.background-active {
                    background-color: var(--active-background);
                }
            }
        }

        &:not(.is-dropdown) {
            @include m.regular-border();
        }
    }

    .navigation-container {
        @include m.flex-column();
        margin-bottom: 0 !important;
    }

    ul {
        list-style: none;
        padding: 8px;

        cx-navbar-option {
            display: block;
            height: 48px;
        }

        .link {
            @include m.flex(center);
            padding: 12px 16px;
            position: relative;
            gap: 8px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .progress-bar-container {
        @media (width > v.$desktop) {
            margin-top: v.$bigPad;
        }
    }

    .nav-arrow {
        margin-left: auto;
        transition: all .1s;
        @include m.flex-center();
        @include m.size(12px, 8px);
    }

    .other-options {
        z-index: 0;
        line-height: v.$regularPad;
        display: none;
    }

    .rotate {
        @include m.flip-animation();
    }

    .options-expanded-container {
        padding: 0;
        transition: all .3s ease-in-out;
    }

    .item.active .option-title {
        color: var(--active-color);
    }

    @include m.media(v.$desktop, false) {
        .navigation-pages:not(.is-dropdown) .link {
            &:hover {
                background-color: var(--hover-background);
            }

            &.background-active {
                background-color: var(--active-background);
            }
        }
    }

    @media (max-width: v.$desktop) {
        min-height: unset;

        .navigation-pages:not(.is-dropdown) {
            @include m.fixedOffset(0, false, false);
            border: unset;
            border-radius: unset;
            width: 100%;
            color: v.$gray-default;

            .item:not(.active) .option-title {
                color: var(--sticky-color);
            }

            .option-title {
                font-size: v.$small;
            }

            .options-expanded-container {
                &:not(.hidden) {
                    height: unset !important;
                }
                padding: unset;
                transition: unset;
            }

            .navigation-container {
                padding: unset;
                flex-direction: row !important;
                margin-bottom: 0;
                margin-inline: 16px;
                justify-content: space-between;
                gap: 4px;

                .item {
                    @include m.container-fluid(100px);
                }
            }

            cx-navbar-option {
                height: 100%;
            }

            .link {
                @include m.flex-center(column);
                border: unset;
                border-radius: unset;
                text-align: center;
                transition: background-position 0.2s;

                &:hover {
                    box-shadow: unset;
                    transition: all 0.2s;
                }
            }

            .child {
                width: 100%;
            }

            .nav-arrow {
                display: none;
            }

            .expanded {
                .other-options {
                    @include m.flex-center(column);
                    position: absolute;
                    width: 100%;
                    margin-left: 0;
                    bottom: calc(100% + 16px);
                }

                .other-options ul {
                    width: 100%;
                    @include m.column-gap(v.$regularPad);
                }

                .other-options li a {
                    @include m.square(63px);
                    @include m.flex-center();
                    border-radius: 50%;
                    background: var(--Color-Grey-100);
                    margin-inline: auto;
                    overflow: hidden;

                    .link {
                        gap: 0;
                        padding-block: 0;
                        @include m.full-size();

                        .option-title {
                            @include m.font(v.$extrasmall, 400, 12px, $important: true);
                        }
                    }
                }
            }
        }
    }

    @media (max-width: v.$tablet) {
        .navigation-pages:not(.is-dropdown) {
            .link {
                @include m.font(null, 500, 13px, 0.352px);
                text-align: center;
                font-style: normal;
            }

            .link:hover {
                font-style: normal;
                opacity: 1;
            }

            ul li .link {
                color: v.$gray-default;
            }
        }
    }

    @media (max-width: v.$phone) {
        .navigation-pages:not(.is-dropdown) {
            @include m.flex(flex-end);
            @include m.fixedOffset(0, false, false);
            border: unset;
            border-radius: unset;
            width: 100%;

            .link:hover {
                font-weight: normal;
                font-style: normal;
                opacity: 1;
            }

            .navigation-container {
                margin-inline: 0;
                width: 100%;

                .link {
                    padding: 16px 5px;
                }

                pointer-events: auto;
            }

            section[data-scrollbar] {
                max-height: 200px;
            }

            .option-title {
                @include m.font(v.$extrasmall, 400, 12px, 0.24px);
            }
        }
    }
}
