@import 'styles/variables';

#cx-currency-pill {
    .currency-pill {
        height: 20px;
        width: 20px;
        background-color: $purple-text !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;

        &.large {
            height: 28px;
            width: 28px;
        }

        &.white-bg {
            background-color: $white !important;
            span {
                color: $purple-text !important;
            }
        }
        
        span {
            font-size: 12px !important;
            color: $white !important;
            line-height: 0;
        }
    }
}
