@import 'styles/variables';
@import 'styles/mixins';

#cx-dialog {
    .input-password {
        width: 294px;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .dialog-content {
        line-height: 30px;
        font-size: $regular;
        letter-spacing: 0.1px;
    }

    .dialog-actions {
        padding: 0;
        margin-top: 21px;
    }

    .dialog-title {
        font-size: $extralarge;
        padding-left: 15px;
        flex-shrink: 1;

        @media (width <= $desktop) {
            font-size: $large;          
        }
    }

    .dialog-header {
        background-color: rgba(251, 251, 255, 1);
    }

    .dialog-inner {
        padding: 46px 40px 40px;    
        @include flex(initial, initial, row);

        @media (width <= $desktop) {
            padding: 20px;       
        }

        @media (width <= $phone ) {
            @include flex(center, initial, column);
        }
    }

    .otp-container {
        width: 350px;

        @media (max-width: $phone) {
            width: 310px;
        }
    }
    
    .circle-icon {
        height: 17px;
        position: absolute;
        right: 194px;
        top: 16px;
        width: 16px;
    }

    .side-image {
        @include container-fluid(80px); 
        
        @media (width <= $phone) {   
            @include container-fluid(116px);        
        }
    }

    .main-content {
        @include flex(initial, initial, column);
        width: 100%;
        margin: 0 56px 0 96px;

        @media (width <= $desktop) {
            margin: 0 0 0 20px;              
        }
        
        @media (width <= $phone) {        
            margin: unset;
        }
    }

    .cancel-btn {
        height: 49px !important;
        width: 189px !important;
        background: $cx-purple-gradient;
        border-radius: 6px;
    }
    
    .mini-spinner-icon {
        position: absolute;
        right: 190px;
        top: 12px;
    }

    .mdc-dialog__surface {        
        min-width: 721px !important;
        
        @media (width <= $desktop) {    
            min-width: 541px !important;
        }

        @media (width <= $phone) {
            min-width: calc(100% - 40px) !important;
        }        
    }
    
    .mdc-dialog .mdc-dialog__content {
        padding: 0 !important;
    }

    .confirm-btn {
        width: 189px;
        height: 49px;
        
        @media (width <= $phone) {
            margin-bottom: 28px;
        }
    }

    @media (max-width: $tablet) {
        .dialog-content {
            font-size: $regular;
        }
        
        .dialog-title {
            font-size: $large;
        }

        .circle-icon {
            right: 157px;
            top: 16px;
        }
        
        .mini-spinner-icon {
            right: 153px;
            top: 12px;
        }
    }

    @media (max-width: $phone) {
        .dialog-inner {
            line-height: 24px;
        }

        .circle-icon {
            right: 40px;
            top: 16px;
        }
        
        .mini-spinner-icon {
            right: 36px;
            top: 12px;
        }
    }

    @media (max-width: $smallphone) {        
        .link-styling {
            @media (max-width: $smallphone) {
                margin-right: 15px !important;
            }
        }
    }
}
