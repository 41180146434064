@import 'styles/variables';

#cx-currency-dropdown {
    height: 100%;
    padding: 0 $medium;

    .btn {
        width: 125px;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-top: unset;
        border-bottom: unset;
        border-radius: 0;
        white-space: nowrap;
        column-gap: 8px;
        transition: all .15s ease-in-out;
        position: relative !important;
        --bs-btn-border-width: none !important;

        span {
            font-size: $regular;
            font-weight: 500;
        }
        span.font-arrow {
            font-size: $extralarge;
        }

        &.main-dropdown-menu {
            color: $purple-text;
        }
    }

    .main-button-hover,
    .btn:hover {
        &::after {
            content: '';
            position: absolute;
            left: 0%;
            top: 96%;
            width: 100%;
            height: 2px;
            background-color: $cx-purple;
        }

        span.arrow.alt-customer-dropdown-icon-down {
            transform: rotate(180deg);
        }
    }

    &.inverted {
        .main-button-hover,
        .btn:hover {
            &::after {
                background-color: $white;
            }
            span.arrow.alt-customer-dropdown-icon-down {
                transform: rotate(180deg);
            }
        }
    }

    .btn:active, &:active {
        box-shadow: none !important;
        outline: none !important;
        border-color: unset !important;
    }

    .alt-customer-dropdown-icon-down {
        transform: rotate(0deg);
        margin-left: 0;
        transition: transform ease 0.2s;
    }

    .selected-flag {
        height: 24px;
        width: 24px;
        border-radius: 100%;
    }

    .main-currency-accordion {
        width: 100vw;
        right: 0;
        transition: max-height .1s ease-in-out;
        height: 100vh;
        max-height: 288px;
        background: $white;
        padding: 40px 200px;

        .mdc-text-field--with-leading-icon {
            padding-left: 16px !important;
        }

        hr {
            margin: 20px 0;
        }

        h2 {
            color: rgb(82, 82, 82);
            margin-bottom: 16px;
        }

        h2.currency-title {
            margin-bottom: 20px;
        }
    }

    .mdc-lookup {
        .mdc-menu-surface {
            border-radius: 0;
            margin-top: 2px;
            background-color: rgba(252, 252, 252, 1);
            box-shadow: none;
            border: 1px solid rgb(231, 231, 231);
            border-radius: $thinborder;
            overflow-y: hidden !important;
        }

        .mdc-ripple-surface {
            .mdc-list-item__ripple:before,
            .mdc-list-item__ripple:after {
                background: unset;
                transition: .2s ease-in-out;
            }
        }

        .mdc-list-item {
            padding-left: 10px;
            transition: .2s ease-in-out;

            &:hover {
                background: $ghost-white;
            }
            
            &:active {
                background: $secondarycolor;
            }
        }
    }

    .always-show-label {
        input,
        .mdc-floating-label {
            font-size: $regular !important;
        }

        .mdc-floating-label {
            color: $gray-blue !important;
        }
    }

    div[data-simplebar] {
        padding: $medium 0;
    }
}

