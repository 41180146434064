@use 'styles/variables' as var;

#cx-credit-card-form {
    padding-bottom: 11px;

    .input-card-number {
        margin-bottom: 10px;
    }

    .buttons-container {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid var.$secondaryborder;

        & span {
            letter-spacing: 0.095px;
        }

        .save-button {
            color: var.$purplehover;
        }
    }

    &:has(.pac-container:not([style*='display: none'])) {
        margin-top: 50px;
    }
}
