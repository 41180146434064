@use 'styles/variables' as v;
@use 'styles/mixins' as m;

.check-container {
    @include m.custom-checkbox(16px, white, v.$purple-text, v.$secondarycolor);
    @include m.column-gap(4px);

    &__form-field {
        @include m.flex-gap(v.$smallPad);
    }

    &__subtitle,
    &__title {
        @include m.default-font();
        color: rgb(82, 82, 82);
        line-height: 16px;
    }

    &__subtitle {
        @include m.font(v.$small, 300, $spacing: 0.012px);
    }

    &__title {
        @include m.font(v.$regular, 400, $spacing: 0.014px);
        padding-left: 0 !important;
    }
}
