@use '@material/textfield';
@use '@material/circular-progress/_index';
@use 'styles/variables' as var;

#cx-credit-card-input {
    height: 50px;
    background-color: var.$inputgray;
    border-radius: 12px;
    padding: 0 15px;

    .first-input {
        width: 95px;
        margin-right: 15px;

        &--empty {
            width: 80px;
            margin-right: 48px;
        }
    }

    .middle-input {
        width: 50px;
        margin-right: 15px;

        &--empty:not(.mdc-text-field--focused){
            width: 74px;
            margin-right: 7px;
        }
    }

    .last-input {
        width: 40px;

        &--empty {
            width: 27px;
        }
    }

    &:hover {
        outline: 1px solid var.$purplehover;

        &.input-credit-card--error {
            outline: 1px solid var.$red-hover;
        }

        &.input-credit-card--success {
            outline: 1px solid var.$success-green-hover;
        }

        &.input-credit-card--warning {
            outline: 1px solid var.$yellow-hover;
        }
    }

    &:has(.mdc-text-field--focused) {
        outline: 1px solid var.$purple-text; 
        
        &.input-credit-card--error {
            outline: 1px solid var.$red;
        }

        &.input-credit-card--success {
            outline: 1px solid var.$success-green;
        }

        &.input-credit-card--warning {
            outline: 1px solid var.$yellow;
        }
    }

    .input-password {
        @include textfield.label-color(var.$semi-black);
        @include textfield.ink-color(var.$black);
        background-color: var.$inputgray;
        font-size: var.$small;
        height: 30px !important;
        padding: 0 !important;
        transition: width 0.3s ease;

        &.mdc-text-field--disabled { 
            .mdc-text-field__input {
                color: var.$black;                
            }

            .mdc-floating-label {
            color: var.$semi-black;
            }
        }

        &.mdc-text-field--label-floating {
            @include textfield.label-color(var.$semi-black);
        }

        &:has(.mdc-floating-label--float-above) {
            transform: translateY(5.75px);
            transition: all 0.1s;
        }

        mdc-notched-outline {
            & > .mdc-notched-outline__leading,
            & > .mdc-notched-outline__trailing {
                display: none;
            }
        }

        input {
            font-size: var.$regular;
            height: var.$medium;
            position: absolute;
            bottom: 5px;
            text-overflow: ellipsis;
        }
    }

    .mdc-floating-label {
        font-size: var.$regular;
        text-overflow: clip;
        overflow: visible;
        left: 0;
    }

    .using-label .mdc-notched-outline--no-label .mdc-notched-outline__notch {
        display: block;
    }

    .cx-form-control {
        @include textfield.focused-outline-color(var.$transparent);
        @include textfield.hover-outline-color(var.$transparent);
        @include textfield.outline-color(var.$transparent);
    }

    .mdc-notched-outline .mdc-floating-label--float-above,
    .mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
    .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        transform: translateY(-25.75px) !important;
        font-size: var.$small;
    }

    .circle-icon,
    .mini-spinner-icon {
        right: 15px;
        top: 16px;
    }

    .mdc-circular-progress__determinate-circle,
    .mdc-circular-progress__indeterminate-circle-graphic {
        stroke: var.$purple-text;
    }
}
