@use 'styles/variables' as var;

#cx-crypto-price-card {
    width: 100%;
    height: 297px;
    background-color: var.$white;
    border: 1px solid var(--cg-grey-400);
    border-radius: var.$small;
    display: flex;
    flex-direction: column;

    @media (width <= var.$widescreen) {
        width: 180px;
    }

    @media (width <= var.$desktop) {
        width: 199px;
        height: 242px;
    }

    @media (width <= var.$phone) {
        width: 165px;
    }

    .learn-more-link {
        color: var.$purple-text;
        line-height: 18.75px;
        max-width: max-content;
        font-weight: 500;

        &.skeleton {
            width: 111px;
            height: 21px;
        }

        &:hover {
            > span:first-child {
                text-decoration: underline !important;
            }
        }
    }

    .price-container {
        margin-top: var.$extralarge;

        @media (width <= var.$desktop) {
            margin-top: 5px;
        }
    }

    .currency-price {
        &.skeleton {
            height: 21px;
            width: 102px;
            margin-top: var.$extralarge;
        }
    }

    .percent-container {
        &.skeleton {
            height: 21px;
            width: 67px;
        }
    }

    .skeleton-percentage {
        height: 21px;
        width: 67px;
    }

    .currency-logo {
        height: 40px;
        width: 40px;

        &.skeleton-loading {
            border-radius: 100%;
        }
    }

    .currency-name {
        color: var.$darkergray;
        line-height: 18.75px;
        margin-top: var.$tiny;

        &.skeleton {
            height: 13px;
            max-width: 49px;
            margin-top: 13px;
        }
    }

    .upward-trend {
        color: rgba(39, 174, 96, 1);

        .material-icons {
            margin-left: -6px;
        }
    }

    .downward-trend {
        color: var.$red;
    }

    &:hover, &:active {
        border: 1px solid var.$purplehover;
    }

    &:active {
        border: 2px solid var.$purplehover;
    }

    .height-23{
        height: 23px;
    }

    .height-margin {
        height: var.$extralarge;
        margin-top: 5px;
        margin-left: 33px;

        @media (width <= var.$desktop) {
            margin-left: var.$large;
        }

        &.skeleton-loading {
            margin-left: 32px;

            @media (width <= var.$desktop) {
                margin-left: 24px;
            }
        }
    }

    .head-container-card {
        padding: var.$huge var.$largeplus 0 var.$moreplushuge;

        @media (width <= var.$desktop) {
            padding: var.$largeplus var.$largeplus 0 var.$extralarge;
        }
    }

    .footer-container-card {
        padding: var.$regularplus 0 0 var.$huge;

        @media (width <= var.$desktop) {
            padding: 0 0 0 var.$largeplus;
        }
    }

    .container-graph {
        margin: 0px 5px;
        height: 61px;

        @media (width <= var.$desktop) {
            height: 50px;
        }
    }

    .skeleton-loading {
        &.learn-more-link {
            display: block;
            width: 120px;
            height: 24px;
            max-width: unset;
        }

        &.skeleton-price {
            height: 21px;
            width: 130px;

            @media (width <= var.$desktop) {
                width: 120px;
            }

            @media (width <= var.$phone) {
                width: 100%;
            }
        }
    }

}
