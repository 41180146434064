#cash-in-person {
    .tooltip-detail {
        line-break: auto;
    }

    .mdc-expandable__header.mdc-expandable__header--closed {
        overflow: inherit;
        z-index: 1;
    }
}
