@import 'styles/variables';

#tracking-number {
    .text-address {
        font-size: $small;
        margin-bottom: 10px;

        @media (max-width: $desktop) {
            font-size: $tiny;
        }
    }

    .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: $regular;

        @media (max-width: $desktop) {
            font-size: $tiny;
        }
    }

    .mdc-list-item__content .file-name {
        @extend .file-name;
    }

    .wrapper-sm {
        padding-top: 0;
        padding-left: 3px;
        margin-top: -5px;

        @media (max-width: $tablet) {
            padding-left: 4px;
            padding-top: 0;
        }

        @media (max-width: $phone) {
            padding-left: 0;
            padding-top: 0;
            align-self: flex-end;
            align-items: flex-end;
        }

        & .cx-first-files-list {
            width: 90%;
            flex-basis: fit-content;
            max-width: 240px;

            & .mdc-list {
                list-style-type: none;
                display: flex;

                & .mdc-list-item {
                    padding-left: 2px;
                    padding-right: 2px;
                    margin-right: 6px;
                    max-width: max-content;
                    flex: 1 1 0;

                    &.mdc-list-item--with-one-line {
                        float: left;
                        height: $extralargeplus;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.05);
                            border-radius: 4px;
                        }
                    }
                }
            }

            & .mdc-list-item__content {
                pointer-events: auto;
            }

            @media (max-width: $desktop) {
                max-width: 135px;
            }
        }

        & .cx-dropdown-files-list {
            max-width: 15rem;
            padding: 0.25rem;
            transform: translate(0, 25px) !important;
            inset: 0 0 auto auto !important;

            & .mdc-list-item {
                padding-left: 0;
                height: 25px;

                &.mdc-list-item--with-one-line {
                    &:hover {
                        background-color: rgba(0, 0, 0, 0.05);
                        border-radius: 4px;
                    }
                }
            }

            & .mdc-list-item__content {
                pointer-events: auto;
                max-width: 170px;
            }
        }

        .cx-dropdown-files-list {
            overflow: auto;
            max-height: 210px;

            &::-webkit-scrollbar-track {
                background-color: $white;
            }

            &::-webkit-scrollbar {
                width: $largeplus;
            }

            &::-webkit-scrollbar-thumb {
                background-clip: content-box;
                background-color: rgba(176, 176, 176, 0.5);
                border: 7px solid $white;
                border-radius: $largeplus;

                &:hover {
                    background-color: rgba(176, 176, 176, 0.6);
                }

                &:active {
                    background-color: rgba(176, 176, 176, 0.7);
                }
            }
        }

        div.dropend {
            width: $extralargeplus;
            margin-left: 2px;

            @media (max-width: $tablet) {
                left: -13px;
            }
        }
    }
    
    .attach-icon {
        width: 11px;
        height: $largeplusplus;
        font-size: 20px;

        @media (max-width: $tablet) {
            font-size: 15px;
        }
    }

    .info-section {
        background-color: $backgroundcontrol;
        padding: 20px;
        max-height: 206px;
        margin-bottom: 28px;
    }
    
    .info-scrollbar {
        max-height: 127px;
    }
    
    .info-scrollbar .simplebar-horizontal {
        display: none !important;

    }

    .info_shield {
        margin-right: 14px;
    }

    .help-header {
        p {
            margin-bottom: 0 !important;
        }
    }

    a {
        color: $purple-text !important;
        &:hover {
            text-decoration: underline !important;
        }
    }

    .tooltip-detail {
        top: -10px;
        width: 496px; 
        max-width: none;
        height: 172px;
        font-weight: 400;
        padding: 10px 26px;
        z-index: 1;
        line-break: auto;

        .caption {
            color: $lightgray !important;
        }

        @media (max-width: $desktop) {
            height: auto;
            top: 30px;
            left: unset;

            &::after {
                left: 30%;
            }
        }

        @media (max-width: $phone) {
            width: 330px;

            &::after {
                left: 50%;
                margin-left: -5px;
            }
        }
    }

    .country-selector {
        left: 0;
        width: 300px;
        height: auto;
        background-color: $white;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.160784);
        border-radius: 4px;
        z-index: 99;

        .option {
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid $secondaryborder;
        }

        .last-option {
            border-bottom: none !important;
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }

        .option-flag {
            margin-right: 8px;
            width: 24px;
            height: 14px;
        }

        @media (max-width: $phone) {
            left: auto;
        }
    }

    .circle-icon {
        font-size: $largeplusplus;
        height: 20px;
        width: 20px;
    }

    .mdc-expandable__header.mdc-expandable__header--closed {
        overflow: inherit;
        z-index: 1;
    }

    .mini-spinner-icon,
    .circle-icon {
        position: absolute;
        right: 20px;
        top: 11px;
    }
}
