@import 'styles/variables';

#document-upload {
    mdc-data-table {
        border: none;
    }
    
    .mdc-data-table__header-row, .mdc-data-table__row {
        height: auto;
    }
    
    thead {
        th {
            border: none !important;
        }
    }
    
    tbody {
        font-size: $regular;
    
        tr {
            &:hover {
                background: none !important;
            }
        }
    }
    
    .checkmark {
        height: 16px;
        width: 16px;
        margin-left: 10px;
        content: url('/static/icons/checkmark_unfilled.svg');
    }
    
    .verification-button {
        all: unset;
        height: 64px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
