@use '@material/textfield';
@import 'styles/variables';

#cx-currency-text-input-selector {
    display: flex;
    width: 178px;
    height: 34px;
    justify-content: right;

    .dropdown-input {
        @include textfield.ink-color($input-placeholder);
        @include textfield.prefix-color($input-placeholder);
    }

    .input-password:not(.mdc-text-field--disabled) {
        border-left: 1px solid $inputgray;
        border-top: 1px solid $inputgray;
        border-bottom: 1px solid $inputgray;
    }

    .select-background:not(.mdc-select--disabled) .mdc-select__anchor {
        border-right: 1px solid $inputgray;
        border-top: 1px solid $inputgray;
        border-bottom: 1px solid $inputgray;
    }

    &:hover {
        .input-password:not(.mdc-text-field--disabled) {
            border-left: 1px solid $purplehover;
            border-top: 1px solid $purplehover;
            border-bottom: 1px solid $purplehover;
        }

        .select-background:not(.mdc-select--disabled) .mdc-select__anchor {
            border-right: 1px solid $purplehover;
            border-top: 1px solid $purplehover;
            border-bottom: 1px solid $purplehover;
        }

        .dropdown-input {
            @include textfield.ink-color($input-placeholder-hover);
            @include textfield.prefix-color($input-placeholder-hover);
        }
    }    

    &:has(.mdc-text-field--focused) {
        .input-password:not(.mdc-text-field--disabled) {
            border-left: 2px solid $purple-text;
            border-top: 2px solid $purple-text;
            border-bottom: 2px solid $purple-text;
        }
        
        .select-background:not(.mdc-select--disabled) .mdc-select__anchor {
            border-right: 2px solid $purple-text;
            border-top: 2px solid $purple-text;
            border-bottom: 2px solid $purple-text;
        }

        .dropdown-input {
            @include textfield.ink-color($black);
            @include textfield.prefix-color($black);
            @media (max-width: $phone) {
                font-size: 12;
            }
        }
    }

    .currency-flag {
        width: 20px;
        height: 14px;
    }

    .crypto-icon {
        width: 22px;
        height: 22px;
    }

    .text-mode{
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
            cursor: pointer;
            border: 1px solid $purple-text;
            border-radius: 8px;
            padding: 0 15px 0 7px;
        }
    }

    .currency-dropdown .mdc-list-item {
        padding-left: 10px;
        padding-right: 15px;
    }

    .expandable-arrow {
        transition: all .2s ease;
        color: $purple-text;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    section[data-simplebar] {
        max-height: 330px !important;
        width: 300px !important;
    }


    //MDC OVERRIDES
    .mdc-select .mdc-select__anchor {
        height: 34px;
        width: 34px;
        padding-left: 0px;
        border-radius: 12px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .mdc-text-field {
        height: 34px;
        max-width: 200px;
        border-radius: 12px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .mdc-select .mdc-menu-surface {
        min-width: 300px;
        left: -145px !important;
    }

    .mdc-line-ripple {
        display: none;
    }

    .mdc-text-field__affix--prefix {
        color: black;
    }

    .mdc-select__selected-text-container {
        display: none;
    }

    .mdc-select__dropdown-icon {
        margin: auto;
    }

    .mdc-text-field--filled:hover .mdc-text-fieldripple::before,
    .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-fieldripple::before {
        opacity: 0;
    }

    .mdc-list-item:hover {
        background: $lilac-hover;
    }

    .input-password:not(.mdc-text-field--disabled),
    .select-background:not(.mdc-select--disabled) .mdc-select__anchor {
        background-color: $inputgray;
    }

    .mdc-select__anchor:hover .mdc-select__ripple::before,
    .mdc-select__anchor.mdc-ripple-surface--hover .mdc-select__ripple::before,
    .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
    .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
    .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
    .mdc-text-field--filled:not(.mdc-ripple-upgrade):focus .mdc-text-field__ripple::before,
    :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before,
    :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before,
    .mdc-select__anchor.mdc-ripple-upgraded--background-focused .mdc-select__ripple::before,
    .mdc-select__anchor:not(.mdc-ripple-upgraded):focus .mdc-select__ripple::before {
        opacity: 0;
    }

    .mdc-text-field__affix--prefix {
        font-size: 16px;
        @media (max-width: $phone) {
            font-size: 12px;
        }
    }

    .currency-category-title {
        color: $midgray;
    }
}
