@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#cx-card {
    @include m.regular-border();
    background: v.$color-grey-100;
    border-radius: 4px;

    .card-main {
        @include m.flex-gap(v.$smallPad, $direction: column);

        &.card-padding {
            padding: v.$regularPad;
        }
    }

    .card-title {
        @include m.flex(center, space-between);
        border-bottom: 1px solid v.$slateblue-off-lower-opacity;
    }
}
