@use '@material/textfield';
@import 'styles/variables';

#cx-dropdown {
    .mdc-text-field--outlined {
        height: 43px;
    }

    .mdc-text-field__icon--leading {
        margin-left: 8px;
    }

    .expanded-selected {
        z-index: 50;
        width: 100%;
        border-radius: 12px;
    }

    .expand-icon {
        position: absolute;
        right: 5px;
        transform: rotate(0deg);
        transition: 0.3s ease;
        color: $input-placeholder;
    }

    .show {
        .expand-icon {
            transform: rotate(180deg);
            transition: 0.3s ease;
        }
    }

    .input-placeholder {
        padding-left: 20px !important;
        font-size: 16px;
    }

    .btn:focus {
        box-shadow: none;
    }

    .dropdown {
        color: $input-placeholder;
        background-color: $inputgray !important;
        
        &:hover {
            outline: 1px solid $purplehover;
            color: $input-placeholder-hover;
        }
    }

    .dropdown-borderless {
        color: $input-placeholder !important;
        background-color: $inputgray !important;  
    }

    .dropdown-menu {
        background-color: $inputgray;
        border: none;
        padding: 20px 12px;
        font-size: $regular;
        color: $input-placeholder;
    }

    .menu-option {
        &:hover {
            color: $purple-text;
        }
    }

    .border-radius-full {
        border-radius: 12px !important;
    }

    .border-radius-mid {
        border-radius: 0 !important;
    }

    .border-radius-end {
        border-radius: 0 12px 12px 0;
    }

    .dropdown-scrollbar {
        max-height: 180px;
        overflow-x: hidden;
    }

    .dropdown-scrollbar .simplebar-horizontal {
        display: none !important;
    }

    .p-btn {
        background-color: $dropdownmenu;
        min-height: 50px;
        border: none;
    }

    @media (max-width: $phone) {
        .input-placeholder {
            font-size: 12px;
        }
    }
}
