@use '~@material/button';
@import 'styles/variables';

#chat-view {
    #title {
        max-width: 700px;
        font-size: $largeplus;

        @media (max-width: $tablet) {
            max-width: 510px;
        }

        @media (max-width: $phone) {
            text-overflow: unset;
            white-space: unset;
            overflow: unset;
            max-width: 100%;
            font-size: $medium;
            margin-bottom: 20px;
        }
    }

    .single-container {
        background: $white;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        flex: 1 1 auto;
        height: auto;
        min-height: 332px;
        padding: 40px 30px;
    }

    #cx-button .resolve:not(:disabled) {
        color: $purple-text !important;
    }

    .send-btn {
        &.disabled {
            background: $purple-disabled !important;
            opacity: 35%;
            @include button.container-fill-color($transparent);
            @include button.ink-color($darkergray);
        }
    }

    #cx-support-issue-input {
        height: 90px;

        & .text-area {
            height: 70px;
            padding-right: 10px !important;

            & .mdc-text-field__resizer {
                padding: 0;
            }
        }

        & #drop-zone {
            height: 72px;
        }

        & #wrapper-sm {
            margin-top: 44px;
            z-index: 0;

            @media (max-width: $phone) {
                margin-top: 20px;
                z-index: 1;
            }
        }

        & .drop-zone-lg img {
            margin-bottom: 0;
            width: 40px;
            height: 40px;
        }
    }

    .messages {
        max-height: 315px;
        padding: 8px 0 32px;
        overflow: hidden;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 18px;
        }

        &::-webkit-scrollbar-thumb {
            background-clip: content-box;
            background-color: $purple-text;
            border: 7px solid transparent;
            border-radius: 20px;

            &:hover {
                background-color: $purple-text;
            }

            &:active {
                background-color: $purple-text;
            }
        }

        @media (max-width: $tablet) {
            max-height: 196px;
        }

        @media (max-width: $phone) {
            max-height: 220px;
            margin-right: -18px;
        }
    }

    .btn-container {
        z-index: 1;

        @media (max-width: $phone) {
            z-index: 0;
            padding-top: 45px;
        }
    }

    .divisor-header {
        @media (max-width: $tablet) {
            width: calc(100% + 40px);
            border-bottom: 1px solid $divisor-gray-light;
            margin: 20px 0 20px -20px;
        }

        @media (max-width: $phone) {
            width: calc(100% + 30px);
            margin: 20px 0 20px -15px;
        }
    }
}
