@use '@chicksgroup/styles/mixins' as m;
@use '@material/textfield';
@import 'styles/_variables.scss';

#cx-currency-selector {
    .mdc-text-field {

        &--outlined {
            height: 43px;
        }

        &__icon--leading {
            margin: 0;
            height: 24px;
            display: flex;
        }

        .mdc-floating-label {
            font-size: $regular;
            line-height: 16px;
            left: 29px;
            max-width: calc(100% - 30px);
            color: $color-grey-700;
            padding-left: 3px;
        }

        &.selected {
            .mdc-floating-label {
                transform: translateY(-106%) !important;
                font-size: 12px;
                color: $color-grey-700;
            }
            
            .mdc-text-field__input {
                color: $color-grey-900;
            }
        }

        .mdc-text-field__input {
            color: $color-grey-700;
            padding-left: 8px;
        }
    }

    &:hover {
        .mdc-text-field__input {
            color: $color-grey-900;
        }
    }

    &:focus,
    &:focus-within {
        .mdc-text-field__input {
            color: $color-grey-900;
        }
    }

    .currency-selector {
        width: 80%;
        white-space: nowrap;
    }

    .currency-selector-dropdown {
        margin-top: 2px;
    }

    .other-currencies {
        padding-top: 10px !important;
        border-top: solid 1px $secondaryborder;
    }

    .other-currencies::before {
        content: '';
        background-color: $white;
        opacity: 0.1;
        width: 100%;
        height: 1px;
        margin-top: -9px;
        position: absolute;
        right: 3px;
        display: inline-block;
        z-index: 100;
        opacity: 1;
    }

    .expanded-selected {
        margin-top: 15px !important;
        z-index: 50;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;

        @media (width < $desktop) {
            margin-top: 2px !important;
        }
    }

    .simplebar-content {
        padding: 20px 0px !important;
    }

    .balance-container {
        display: none;
        position: absolute;
        top: 40px;
        left: 75px;
    }

    .dropdown-menu[data-bs-popper] {
        left: unset !important;
        right: 0px;
        max-height: 396px;
    }

    .balance-container:hover {
        display: block;
    }

    .expand-icon {
        --cds-icon-color: var(--cg-grey-700);
        transform: rotate(0deg);
        transition: 0.3s ease;
        color: $purple-text;
        margin-left: 4px;
    }

    .show {
        .expand-icon {
            transform: rotate(180deg);
            transition: 0.3s ease;
        }
    }

    .balance-text {
        cursor: pointer;
        margin-bottom: 5px;
    }

    .btn:focus {
        box-shadow: none;
    }

    .mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path {
        animation: none;
    }

    .currency-area-header {
        padding: 0 15px 5px 15px;
    }

    .currency-item {
        padding: 7px 10px;
        cursor: pointer;

        &:hover {
            background: $color-grey-250;
        }

        &>div {
            padding: 5px;
            border-radius: 4px;

            &:hover {

                +.balance-container {
                    display: block;
                }

                .container-balance-padding {
                    min-height: 70px;
                }
            }
        }
    }

    .close-icon {
        top: 30px;
        right: 28px;
    }

    .currency-image {
        max-width: 24px;
        max-height: 100%;
    }

    .selected-currency-img,
    .currency-img {
        display: inline-block;
    }

    .selected-currency-img {
        @include square(24px);
        border-radius: 50%;
        object-fit: cover;
    }

    .currency-img {
        vertical-align: middle;

        img {
            @include square(24px);
            vertical-align: middle;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .payment-method-text-field {
        height: 50px;
        background-color: transparent;


        @media (width < $desktop) {
            height: 52px;
        }
    }

    .currency-selector-section {
        height: 50px;
        max-height: 50px;
        color: $input-placeholder;
        border-radius: 4px;
        border: none;
        padding: 8px 16px;
        justify-content: space-between;
        background-color: var(--cg-grey-250);

        &:hover {
            color: $input-placeholder-hover;
            outline: 1px solid $color-secondary-600;
            background-color: var(--cg-grey-300);
        }

        &:focus, &:focus-within {
            outline: 2px solid $color-secondary-600;
            background-color: var(--cg-grey-350);
        }

        @media (width < $desktop) {
            height: 52px;
            max-height: 52px;
            padding: 8px 16px;
        }
    }

    .custom-checkbox {
        padding: 2px !important;
        width: 16px !important;
        height: 16px !important;
        top: 0;
        left: 0;

        .mdc-checkbox__native-control,
        .mdc-checkbox,
        .mdc-checkbox__background {
            padding: 2px !important;
            width: 16px !important;
            height: 16px !important;
            top: 0;
            left: 0;
        }
    }

    .payment-method-scrollbar {
        max-height: 370px;
        padding: $regularPad $smallPad;
    }

    .payment-method-scrollbar .simplebar-horizontal {
        display: none !important;
    }

    .search-bar {
        @include textfield.outline-shape-radius(4px);
        @include textfield.shape-radius(4px);
        background-color: transparent;
    }

    @media (max-width: $phone) {
        .currency-selector-dropdown {
            padding-left: 10px;
        }
    }

    .animated-text {
        transition: transform 0.5s ease-in-out;
    }

    #cx-no-results {
        padding: 0 16px;

        img {
            @include m.square(32px);
        }

        span {
            font: 300 14px/16px $primaryfont;
            letter-spacing: 0px;
        }
    }
}
