@use '@material/checkbox';
@import 'styles/variables';

#cx-checkbox-filter {
    #container-filter:hover {
        .select-box-container {
            display: flex;
        }

        .select-arrow {
            transform: rotate(180deg);
        }
    }

    .option {
        height: 26px;
        padding: 0 5px;

        &:hover {
            background-color: $lilac-hover;
            border-radius: 4px;

            .option-text {
                color: $purple-text;
            }

            .bullet {
                border: 1px solid $purplehover;
            }
        }
    }

    .option-text {
        color: $midgray;
    }

    .select-arrow {
        user-select: none;
        color: $midgray;
    }

    .select-box {
        width: 25px;
        height: 25px;
        border-radius: 3px;
        margin-right: 10px;
        background-color: $inputgray;
    }

    .select-box-dropdown {
        width: 160px;
        height: 50px;
        background-color: $white;
        border: 1px solid $select-gray;
        border-radius: 4px;
        padding: 0 19px;
        color: $midgray;

        &:hover {
            border: 1px solid $purplehover;
            color: $purple-text;

            .select-arrow {
                color: $purple-text;
            }
        }

        &:active {
            border: 1px solid $purple-text;

            .select-arrow {
                color: $purple-text;
            }
        }

        & > div {
            min-width: 103px;

            & span:nth-child(2) {
                max-width: 33px;
            }
        }
    }

    .bullet {
        height: 15px;
        width: 15px;
        border-radius: 4px;
        background-color: $softwhite;
        margin-right: 8px;

        &:hover {
            .option {
                color: $purple-text !important;
            }
        }
    }

    .bullet-active {
        background: $purple-text;
        transition: background 0.3s ease ;
    }

    .select-box-container {
        display: none;
        min-height: 136px;
        min-width: 160px;
        border-radius: 4px;
        background-color: $white;
        border: 1px solid $palegray;
        z-index: 99;
        white-space: nowrap;
    }

    .checkbox-container {
        padding: 15px 12px;
    }

    .dropdown-container {
        padding: 7px 5px 15px 5px;
    }

    .select-spacer {
        height: inherit;
    }

    .select-arrow {
        transition: all ease 0.2s;
    }

    .bullet.checkbox-external {
        height: 25px;
        width: 25px;
        &:hover {
            outline: 1px solid $purplehover;

            &.bullet-active {
                outline: none;
            }
        }

        & .material-icons {
            font-size: 24px;
        }
    }
}
