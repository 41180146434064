@use '~@material/button';
@use '~@material/theme';
@use 'styles/variables' as var;

#cx-button {
    --mdc-typography-button-letter-spacing: normal;
    height: 100%;

    .button-text {
        display: inline-block;
    }

    button[mdc-button] {
        @include button.shape-radius(4px);
    }
    
    .primary {
        @include button.ink-color(var.$color-grey-100);
        @include button.height(auto);
    }

    .primary-style {
        @include button.container-fill-color((
            'default': var.$purple-text,
            'hover': var.$primary-button-hover,
            'pressed': var.$primary-button-pressed
            ));
    }

    .button-h50 {
        height: 52px;
    }

    .primary-disabled {
        background: var.$purple-text !important;
        opacity: 0.2;
        pointer-events: none !important;
        cursor: unset !important;
    }

    .primary-inverted {
        @include button.container-fill-color(var.$white);
        @include button.ink-color(var.$purple-text);

        &:hover {
            @include button.container-fill-color(rgba(255, 255, 255, 0.9));
        }
    }

    .primary-outlined {
        @include button.container-fill-color(transparent);
        @include button.ink-color(var.$cx-purple);
        @include button.height(auto);
        @include button.horizontal-padding(48px);
        @include button.height(50px);
        border: 2px solid var.$cx-purple !important;
        border-radius: 12px !important;

        &:hover {
            @include button.ink-color(rgba(67, 0, 169, 0.8));
            border: 2px solid rgba(67, 0, 169, 0.8) !important;
        }
    }

    .secondary {
        @include button.container-fill-color((
            'default': var.$secondarycolor,
            'hover': var.$secondarycolor-hover,
            'pressed': var.$secondarycolor-hover,
            'disabled': var.$secondarycolor));
        @include button.ink-color(var.$purple-text);

        &:disabled {
            & > div:last-child {
                opacity: 0.5;
                color: var.$purple-text;
            }
        }
    }

    .danger {
        @include button.container-fill-color(('default': var.$red-destructive, 'hover': var.$off-red, 'pressed': var.$red-destructive-pressed, 'disabled': var.$off-red));
        @include button.ink-color(var.$red-text);
        @include button.horizontal-padding(24px);
        @include button.height(50px);

        &:disabled {
            opacity: 0.75;

            & > div:last-child {
                color: var.$red-text;
            }
        }
    }

    .lilac {
        @include button.container-fill-color(var.$lilac);
        @include button.ink-color(var.$white);
        @include button.height(40px);

        &:hover {
            @include button.container-fill-color(rgba(87, 98, 213, 0.9));
        }
    }

    .offlilac {
        @include button.container-fill-color(var.$offlilac);
        @include button.ink-color(var.$offblack);
        @include button.height(50px);

        &:hover {
            @include button.container-fill-color(rgba(87, 98, 213, 0.3));
        }
    }
    
    .purple-background {
        background: var.$purple-text !important;
        @include button.container-fill-color(var.$transparent);
        @include button.ink-color(var.$darkergray);
        @include button.horizontal-padding(4.5rem);
        @include button.height(50px);

        &:hover {
            background: var.$cx-primary-hover !important;
        }
    }

    .purple-background-disabled {
        background: var.$purple-disabled !important;
        opacity: 35%;
        @include button.container-fill-color(var.$transparent);
        @include button.ink-color(var.$darkergray);
        @include button.horizontal-padding(4.5rem);
        @include button.height(50px);
    }
    
    .blue-gradient-background {
        background: var.$cx-blue-gradient !important;
        @include button.container-fill-color(var.$transparent);
        @include button.ink-color(var.$darkergray);
        @include button.horizontal-padding(4.5rem);
        @include button.height(54px);

        &:hover {
            background: var.$cx-primary-hover !important;
        }
    }

    .blue-gradient-second-background {
        background: var.$cx-blue-second-gradient !important;
        @include button.container-fill-color(var.$transparent);
        @include button.ink-color(var.$darkergray);
        @include button.horizontal-padding(4.5rem);
        @include button.height(54px);

        &:hover {
            background: var.$cx-primary-hover !important;
        }
    }

    .text-button {
        @include button.container-fill-color((
            'default': var.$transparent,
            'hover': var.$transparent,
            'pressed': var.$transparent,
            'disabled': var.$transparent));
        @include button.ink-color(var.$purple-text);
    }
}
