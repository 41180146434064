@use '@chicksgroup/styles/mixins' as m;
@use '@material/textfield';
@import 'styles/variables';

#cx-coupon-input {
    margin-left: 10px;
    height: 24px;
    @include m.flex(center, center, row);

    input {
        font-size: $small !important;
        font-weight: 400;
    }

    .circle-icon {
        position: absolute;
        right: 8px;
        top: 6px;
        @include square(14px);
        font-size: $regular;
    }

    .coupon-input {
        padding-left: 10px;
        border-radius: 6px;
        @include textfield.outline-shape-radius(6px);
    }

    .mdc-text-field__input {
        padding-right: 12px;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--textarea) {
        height: 26px;
        width: 101px;
    }

    .mini-spinner-icon {
        position: absolute;
        right: 6px;
        top: 3px;
    }

    .enter-coupon {
        color: var(--cg-grey-600);
        font-weight: 500;
        font-size: $regular;
        letter-spacing: 0;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .coupon-apply-amount {
        color: $success-green;
        font-size: $small;
        font-weight: 400;
    }
}
