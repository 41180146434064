@import 'styles/variables';

#horizontal-list-container {
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    align-items: center;

    .horizontal-list {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        gap: 12px;
        width: calc(100% - 10px);
        padding: 3px 10px 3px 3px;
        position: relative;
    }
    
    .arrow {
        --md-icon-size: 15px;
        text-align: center;
        font-size: $regularplus;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
        color: $purple-text;
        position: absolute;
        right: -4px;
    }
    
    .arrow:hover {
        opacity: 1;
    }
    
    .left-arrow {
        transform: rotate(180deg);
    }

    @keyframes slide-to-left {
        from {
            translate: 0;
        } to {
            translate: -100;
        }
    }

    .fade-out, .fade-in {
        position: absolute;
        width: 130px;
        height: 100%;
        pointer-events: none;
    }

    .fade-out {
        right: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), $color-grey-100);
    }

    .fade-in {
        left: 0;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), $color-grey-100);
        z-index: 30;
    }
}
