@import 'styles/variables';


#cx-dropdown-content {
    @include flex-center();
    position: relative;
    height: 100%;
    width: 144px;

    .btn {
        border: none;
        height: 100%;
        letter-spacing: 0.28px;
    }

    .dropdown {
        height: 100%;
        .arrow {
            --md-icon-size: 12px;
            padding: 4px 2px;
            margin-left: 8px;
            transition: transform ease 0.2s;
        }

        .dropdown-menu {
            width: 100%;
            border: 1px  solid $cx-divider;
            border-radius: 10px;
            top: 53px;

            .dropdown-item {
                color: $black !important;
                font-size: $medium;
                padding: 10px 25px;

                .item-flag {
                    width: 24px;
                    border-radius: 3px;
                    margin-right: 26px;
                }

                &:active,
                &:hover {
                    background-color: $lilac-hover;
                }
            }

            &[data-bs-popper] {
                left: 10px;
                margin-top: 3px;
            }
        }

        &:has(.show) {
            .arrow {
                transform: rotate(180deg);
            }
        }
    }

    .bottom-border::after {
        content: '';
        position: absolute;
        left: 0%;
        top: 96%;
        width: 100%;
        height: 2px;
        background-color: $cx-purple;
        pointer-events: none;
    }

    &.inverted .dropdown .bottom-border::after {
        background-color: var(--cg-grey-100) !important;
        top: 96%;
    }

    .bottom-border.border-bottom-white::after {
        background-color: var(--cg-grey-100);
    }
}
