@use 'styles/variables' as v;
@use 'styles/mixins' as m;
@use '@material/textfield';

#cx-confirm-dialog {
    .mdc-dialog .mdc-dialog__surface {
        @include m.container-fluid(100% !important);
        border-radius: 12px;
    }

    .text-content {
        font-size: v.$regular;
    }

    .dialog-container {
        @include m.flex-gap(v.$bigPad);
        padding: v.$bigPad;
    }

    .dialog-confirm {
        background-color: v.$secondarycolor;
        color: v.$purple-text;
        border: none;
    }

    .dialog-header {
        padding: v.$regularPad v.$bigPad;
    }

    .main-icon {
        font-size: v.$huge;
    }

    .underline-on-hover:hover {
        text-decoration: underline;
    }

    .password-input {
        @include m.container-fluid(364px);

        .mdc-text-field__input {
            font-size: v.$regular;
        }

        .visible-icon {
            opacity: .4;
            margin-right: -4px;
        }

        :hover {
            .circle-icon:not(.visible-icon) {
                display: none;
            }

            .visible-icon {
                display: flex;
            }
        }

        &:has(.mini-spinner-icon) .visible-icon,
        &:not(:hover).check-mark .visible-icon {
            display: none !important;
        }
    }

    .main-content {
        @include m.flex(initial, initial, column);
        width: 100%;
    }

    .close-icon {
        font-size: v.$extralarge;
        color: v.$purple-text
    }

    .dialog-buttons {
        @include m.flex-gap(v.$regularPad);

        &,
        button {
            width: 100%;
            font-size: v.$regular;
            font-weight: 500;
            @include m.border-radius-left(5px);
            @include m.border-radius-right(5px);
        }
    }

    .mdc-dialog {
        @include m.padding-x(113px);

        &__content {
            text-align: center;
        }

        &__title,
        &__actions {
            @include m.no-spacing();
        }

        &__title {
            width: 100%;
            letter-spacing: .1px;
            line-height: 32px;
            font-weight: 700;

            &::before {
                display: none;
            }
        }

        &__actions {
            justify-content: flex-start;
            margin-top: auto;
            padding-top: v.$regularPad;
        }

        &__container {
            @include m.container-fluid(793px);
        }
    }

    .content {
        line-height: 30px;
        letter-spacing: .1px;
    }

    .circle-icon {
        @include m.absoluteCenter(false);
        height: 17px;
        width: 16px;
    }

    .visible-icon {
        z-index: 1;
    }

    .mini-spinner-icon {
        position: absolute;
        right: 11px;
        top: 12px;
    }
    
    .cx-form-control{        
        @include textfield.outline-shape-radius(v.$thinborder);
        border-radius: v.$thinborder;

        &.mdc-text-field--focused {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,    
            .mdc-notched-outline__trailing {
                border-width: 2px !important;
            }
        }
    }

    @include m.media(v.$tablet) {
        .dialog-container {
            padding: v.$regularPad;
            gap: v.$regularPad;
        }

        .dialog-header {
            padding: v.$regularPad;
            position: relative;
        }

        .close-icon {
            @include m.absoluteOffset(12px);
            @include m.no-spacing(true);
        }

        .mdc-dialog__title {
            font-size: v.$large;
        }

        .side-image {
            width: 139px;
        }

        .password-input {
            max-width: 252px;
        }
    }

    @include m.media(v.$phone) {
        .mdc-dialog {
            @include m.padding-x(v.$regularPad);

            &__title,
            &__actions {
                justify-content: center !important;
            }

            &__title {
                line-height: 18px;
                letter-spacing: 1.72px;
            }
        }

        .dialog-container,
        .dialog-buttons {
            flex-direction: column;
        }

        .dialog-buttons {
            gap: 11px;
        }

        .dialog-container {
            gap: v.$regularPad;
        }

        .side-image {
            margin: auto;
            @include m.container-fluid(119px);
        }

        .close-icon {
            @include m.absoluteOffset(v.$smallPad);
        }

        .content {
            text-align: center !important;
        }
    }
}
